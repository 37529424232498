html {
    overflow: hidden
}

html.ready {
    overflow-y: scroll;
}

body {
    width: 100%;
    position: relative;
    font-family: Arial, sans-serif;
    overflow: hidden;
    font-size: 14px
}

ul {
    padding: 0
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #858585
}

ol li, ul li {
    font-size: 14px;
    line-height: 1.8;
    font-family: Arial, sans-serif;
    list-style-type: none;
    padding: 0 25px 0 0
}

ul li i {
    vertical-align: middle;
    padding-right: 5px
}

p {
    font-size: 14px;
    line-height: 2;
    font-weight: 400;
    color: #616a78;
    font-family: Arial, sans-serif
}

a, a:active, a:focus, a:hover, a:visited {
    text-decoration: none;
    outline: 0;
    color: #39c
}

a:hover {
    color: #0c9
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Arial Rounded MT Bold", Nunito, Arial, sans-serif;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    color: #071e55;
    font-weight: 500
}

h1 {
    font-size: 40px
}

h2 {
    font-size: 24px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 20px
}

h5 {
    font-size: 18px
}

@media (min-width: 420px) {
    h1 {
        font-size: 45px
    }

    h2 {
        font-size: 30px
    }

    h3 {
        font-size: 28px
    }

    h4 {
        font-size: 26px
    }

    h5 {
        font-size: 20px
    }
}

@media (min-width: 768px) {
    h2 {
        font-size: 34px
    }
}

input:invalid {
    box-shadow: none
}

[contenteditable].form-control:focus, [type=email].form-control:focus, [type=password].form-control:focus, [type=tel].form-control:focus, [type=text].form-control:focus, input.form-control:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=text]:focus, textarea.form-control:focus, textarea:focus {
    box-shadow: inset 0 -1px 0 #ddd
}

.btn {
    font-family: Arial, sans-serif;
    font-size: 14px
}

.btn:active, .btn:focus {
    outline: 0 !important;
    box-shadow: none
}

.white {
    color: #fff
}

.light-blue {
    color: #39c
}

.blue {
    color: #1c4da1
}

.dark-blue {
    color: #1d3866
}

.teal {
    color: #0c9
}

.green {
    color: #3c3
}

.light-gray {
    color: #d2d7e0
}

.strong {
    font-weight: 700
}

.overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.section-bg {
    background: url(../img/dot-eu-3d.jpg), url(../img/hands.png);
    background-position: top center, bottom center;
    background-repeat: no-repeat;
    background-size: auto 40%, auto 25%;
    width: 100%;
    position: relative;
    padding: 110px 0
}

@media (min-width: 768px) {
    .section-bg {
        background-size: auto 57%, auto 40%;
        background-attachment: fixed;
    }
}

.section-bg .overlay {
    background: #39c;
    opacity: .9
}

.section-white {
    background: #fff
}

.section-grey {
    background: #fafafa
}

header {
    width: 100%;
    position: fixed;
    z-index: 999
}

.navbar {
    width: 100%;
    z-index: 9999;
    padding: 15px 0
}

#language-picker .bootstrap-select .dropdown-menu {
    margin-top: 20px
}

#language-picker .bootstrap-select button {
    background-color: transparent;
    color: #111c45;
    border: none
}

#language-picker .bootstrap-select button {
    background-color: transparent;
    color: #111c45;
    border: 1px solid #111c45;
}

.navbar #language-picker {
    position: relative;
    right: 10px
}

.navbar .navbar-brand {
    position: relative
}

.navbar .navbar-brand img {
    height: 25px
}

@media (min-width: 576px) {
    .navbar .navbar-brand {
        left: 30px
    }

    .navbar .navbar-brand img {
        height: 30px
    }

    .navbar #language-picker {
        right: 30px
    }
}

@media (min-width: 800px) {
    .navbar .navbar-brand {
        left: 100px
    }

    .navbar .navbar-brand img {
        height: 40px
    }

    .navbar #language-picker {
        right: 50px
    }
}

.navbar-fixed-top {
    position: absolute !important;
    background: 0 0;
    border-bottom: none;
    transition: background-color .95s ease 0s
}

.navbar-fixed-top.opaque {
    position: fixed !important;
    top: 0;
    margin-top: 0;
    background-color: #fff !important;
    opacity: 1;
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, .1);
    transition: all .95s ease 0s
}

.navbar-fixed-top.page404 {
    position: fixed !important;
    top: 0;
    margin-top: 0;
    background-color: #1d3866 !important;
    opacity: 1;
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, .1);
}

.navbar .navbar-social {
    display: none
}

@media (min-width: 1400px) {
    .navbar .navbar-social {
        display: block
    }
}

.navbar .navbar-social {
    position: fixed;
    top: 30%;
    left: 30px
}

.navbar .navbar-social li {
    margin: 15px 0;
    text-align: center
}

.navbar .navbar-social li i {
    line-height: 1;
    color: #777;
    font-size: 20px
}

#section-banner {
    position: relative;
    z-index: 950;
    background: linear-gradient(16.0deg, #e7f0fe 10.0%, rgba(255, 255, 255, 0) 90.0%);
    background-size: cover;
}

#section-banner .overlay {
    background: #1d3866;
    opacity: 0.45;
    transition: opacity 0.5s ease-out;
}

html:not(.ready) #section-banner .overlay {
    opacity: 1;
}

@media (min-width: 800px) {
    #section-banner .overlay {
        background: #39c;
        opacity: 0;
    }
}

#section-banner .container {
    position: relative
}

#section-banner-overlay {
    width: 100%;
    background: #000;
    opacity: .25;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

#section-banner .btn-goto-das {
    height: 50px;
    background-color: #39c;
    border: none;
    color: #fff;
    border-radius: 30px 30px;
    font-size: 16px;
    font-weight: 700;
    font-family: Arial, sans-serif;
    transition: background-color .25s ease-in-out;
    -moz-transition: background-color .25s ease-in-out;
    -webkit-transition: background-color .25s ease-in-out;
    padding: 0 35px;
    transform: scale(.75);
    box-shadow: 0 0px 5px rgba(100, 100, 100, 0.5);
    margin-top: 45px;
    display: inline-block;
    width: max-content;
}

#section-banner .btn-goto-das:hover {
    cursor: pointer;
    background-color: #206181;
    transition: background-color .25s ease-in-out;
    -moz-transition: background-color .25s ease-in-out;
    -webkit-transition: background-color .25s ease-in-out
}

#section-banner .powered-by-eurid {
    background-image: url(../img/powered_by_eurid.png);
    background-size: contain;
    position: absolute;
    bottom: 15px;
    right: 30px;
    height: 5vw;
    width: 15vw;
    margin-right: 0;
    max-height: 100px;
    max-width: 300px;
}

#section-banner #people {
    position: relative;
    width: 50%;
    margin-top: 50px;
    top: -40px;
}

#section-banner .slogan {
    color: #111c45;
    font-weight: 700;
    left: 5vw;
    top: 25vh;
    font-size: 2.7rem;
    line-height: 3.0rem;
    text-align: center;
    margin-top: 150px;
}

@media (min-width: 576px) {
    #section-banner #people {
        width: 300px;
        margin-bottom: 75px;
    }

    #section-banner .slogan {
        font-size: 3.0rem;
        line-height: 3.2rem;
        margin-top: 160px;
    }

    #section-banner .btn-goto-das {
        margin-top: 45px;
        transform: scale(0.90);
    }
}

@media (min-width: 768px) {
    #section-banner #people {
        width: 350px;
        margin-top: 175px;
    }

    #section-banner .slogan {
        position: absolute;
        font-size: 2.8rem;
        line-height: 3.0rem;
        margin-top: unset;
        text-align: end;
        top: 180px;
    }

    #section-banner .btn-goto-das {
        transform-origin: right;
        transform: scale(0.90);
    }
}

@media (min-width: 992px) {
    #section-banner #people {
        width: 450px;
        margin-bottom: 60px;
    }

    #section-banner .slogan {
        font-size: 3.8rem;
        line-height: 4.0rem;
    }

    #section-banner .btn-goto-das {
        transform: unset;
    }

    #section-banner .powered-by-eurid {
        margin-right: 35px;
    }
}

@media (min-width: 1200px) {
    #section-banner #people {
        width: 580px;
        margin-bottom: 40px;
    }

    #section-banner .slogan {
        font-size: 4.5rem;
        line-height: 4.8rem;
    }
}

#section-das .form-check-domain {
    margin: 35px 0 30px 0;
    max-width: 420px
}

#section-das .form-check-domain .input-domain {
    max-width: 410px;
    height: 50px;
    background-color: #fff;
    color: #454545;
    font-size: 14px;
    display: inline
}

.btn-das {
    background: #39c;
    color: #fff;
    padding: 0 20px
}

.btn-das:hover {
    cursor: pointer;
    background-color: #0c9;
    transition: background-color .25s ease-in-out
}

.btn-das:active {
    border: 0
}

.embedded-video {
    padding-top: 56.1702%;
    width: 100%;
    margin: auto;
    position: relative
}

.embedded-video iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.btn-circle {
    width: 40px;
    height: 40px;
    padding: 6px 0;
    border-radius: 50%;
    text-align: center;
    font-size: 15px;
    line-height: 1.42857
}

.location {
    font-style: italic;
    color: #7c8595;
    font-size: 14px;
    line-height: 3
}

#section-express-identity {
    padding: 70px 0 30px 0
}

#section-express-identity img {
    max-width: 40vw;
    max-height: 125px;
    margin-bottom: 65px
}

#section-express-identity h2 {
    text-align: center;
}

@media (min-width: 768px) {
    #section-express-identity {
        padding: 100px 0 70px 0
    }

    #section-express-identity h2 {
        text-align: left;
    }
}

@media (min-width: 992px) {
    #section-express-identity {
        padding: 150px 0 120px 0
    }
}

@media (min-width: 1200px) {
    #section-express-identity {
        padding: 250px 0 220px 0
    }
}

#slider-stories {
    padding-bottom: 90px
}

.nav-link .tab-icons {
    float: left;
    margin: 2px 10px 0 2px;
    width: 35px;
    text-align: center;
    vertical-align: middle;
    font-size: 30px;
    position: relative;
    transition: all .5s ease-in-out;
}

.nav-link h3 {
    display: inline;
    position: relative;
    top: 4px;
    font-size: 24px
}

.nav-link {
    line-height: 60px
}

@media (min-width: 420px) {
    .nav-link .tab-icons i {
        font-size: 26px
    }

    .nav-link h3 {
        font-size: 21px
    }

    .nav-link .tab-icons {
        width: 60px;
        margin: 2px 5px 0 0;
    }
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1d3866
}

.section-bg p {
    color: #fff
}

.eurid-blue-1-background {
    background-color: #39c
}

.eurid-blue-2-background {
    background-color: #1c4da1
}

.eurid-blue-3-background {
    background-color: #1d3866
}

.gray-background {
    background-color: #aaa
}

.card {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
    padding: 30px 30px;
    border-radius: .25rem !important;
    background: #f6f8f8;
    border-left: solid 5px #39c
}

#section-das {
    display: grid;
    grid-template-columns:[margin-left] auto [column-left] 50% [column-right] 50% [margin-right] auto [end];
    grid-template-rows:[margin-top] 60px [title] auto [step-one] auto [tips] auto [step-two] auto [margin-bottom] 60px [end];
    width: 100%
}

#section-das .step-one span, #section-das .step-two span {
    position: absolute;
    left: 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    top: 0;
    margin: 0 auto;
    font-size: 24px;
    border-radius: 50%;
    background: #3c3;
    text-align: center;
    display: block;
    color: #fff;
    z-index: 1
}

#section-das .title {
    grid-column: margin-left/end;
    grid-row: title;
    padding: 20px 40px 50px 40px;
    text-align: center;
    max-width: 91vw;
    margin: auto
}

@media (min-width: 700px) and (max-width: 1000px) {
    #section-das .title {
        max-width: 500px
    }
}

#section-das .step-one {
    grid-column: column-left/margin-right;
    grid-row: step-one;
    padding: 0 40px 0 55px;
    position: relative;
    margin: 0 12px;
    width: 100vw
}

#section-das .v-line {
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    margin: 0 23px;
    border-left: solid 2px #ddd;
    top: 0
}

#section-das .step-two {
    grid-column: column-left/margin-right;
    grid-row: step-two;
    padding: 0 40px 0 55px;
    position: relative;
    margin: 0 12px;
    width: 100vw
}

#section-das .tips {
    grid-column: column-left/margin-right;
    grid-row: tips;
    height: 100%;
    width: 100vw;
    margin: 0 12px;
    text-align: left;
    padding: 30px 40px 40px 55px;
    position: relative
}

#section-das .tips .card {
    max-width: 525px
}

#section-das #eligibility-criteria {
    max-width: 525px;
    margin: 25px 0 0 0
}

#section-das #eligibility-criteria ul {
    padding: 0 0 0 10px
}

#section-das #eligibility-criteria ul li {
    list-style-type: disc;
    padding: 0 0 15px 3px
}

@media (min-width: 520px) {
    #section-das {
        display: grid;
        grid-template-columns:[margin-left] auto [column-left] 260px [column-right] 260px [margin-right] auto [end];
        grid-template-rows:[margin-top] 60px [title] auto [step-one] auto [tips] auto [step-two] auto [margin-bottom] 60px [end];
        width: 100%
    }

    .form-check-domain {
        margin: 35px 0 25px 0;
        max-width: 410px
    }

    #section-das .step-one span, #section-das .step-two span {
        left: 0;
        width: 48px;
        height: 48px;
        margin: 0 auto 5px;
        font-size: 27px;
        line-height: 48px
    }

    #section-das .step-one {
        grid-column: column-left/margin-right;
        grid-row: step-one;
        padding: 0 60px;
        position: relative;
        margin: 0 30px;
        width: 520px
    }

    #section-das .step-two {
        grid-column: column-left/margin-right;
        grid-row: step-two;
        padding: 0 65px;
        position: relative;
        margin: 0 0 0 30px;
        width: 520px
    }

    #section-das .tips {
        grid-column: column-left/margin-right;
        grid-row: tips;
        height: 100%;
        margin: 0 30px;
        text-align: left;
        padding: 20px 60px 40px 60px;
        width: 520px
    }

    #section-das .tips .card {
        padding: 30px 35px;
        max-width: 475px
    }

    #section-das #eligibility-criteria {
        width: 100%;
        max-width: 475px
    }
}

#section-das .tips .card h5 {
    margin: 0 0 45px 0
}

@media (min-width: 768px) {
    #section-das .tips {
        padding: 20px 60px 40px 60px;
        margin: 0 30px
    }
}

@media (min-width: 1000px) {
    #section-das {
        grid-template-columns:[margin-left] calc((100% - 1100px) / 2) [column-left] 550px [column-right] 550px [margin-right] auto [end];
        grid-template-rows:[margin-top] 60px [title] auto [step-one] auto [step-two] auto [margin-bottom] 150px [end]
    }

    #section-das .form-check-domain {
        margin: 24px 0
    }

    #section-das .step-one {
        grid-column: column-left;
        grid-row: step-one;
        padding: 0 75px 25px;
        max-width: 100%
    }

    #section-das .step-two {
        grid-column: column-left;
        grid-row: step-two;
        padding: 0 75px;
        margin: 0 10px 0 30px;
        max-width: 100%
    }

    #section-das .tips {
        grid-column: column-right/column-right;
        grid-row: step-one/margin-bottom;
        padding: 0;
        height: 100%;
        max-height: max-content;
        margin: 0 60px 0 0
    }

    #section-das .tips .card {
        max-width: 420px
    }

    #section-das .tips .v-line {
        display: none
    }
}

#section-das .das-results {
    margin-top: 10px
}

#section-security {
    display: grid;
    grid-template-columns:[margin-left] 0 [column-left] 50px [column-right] auto [margin-right] 0 [end];
    grid-template-rows:[margin-top] 30px [content] 1fr [margin-bottom] 30px [end];
    width: 100%;
    transition: all 1s
}

@media (min-width: 420px) {
    #section-security {
        grid-template-columns:[margin-left] 0 [column-left] 70px [column-right] auto [margin-right] 0 [end]
    }
}

#section-security .background-left {
    grid-column: margin-left/column-right;
    grid-row: margin-top/end;
    background-color: #f1faff
}

#section-security .background-right {
    grid-column: column-right/end;
    grid-row: margin-top/end;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    z-index: 1
}

#section-security .tabs {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: content/margin-bottom;
    min-width: 400px
}

#section-security .tabs {
    line-height: 95%;
    position: relative;
    top: 0
}

#section-security .tabs .expand-tabs {
    display: inline-block;
    cursor: pointer;
    margin: 30px 0 0 10px;
    width: 28px;
    font-size: 20px
}

#section-security .tabs .expand-tabs .tab-icons i {
    font-size: 30px
}

@media (min-width: 420px) {
    #section-security .tabs .expand-tabs {
        display: inline-block;
        cursor: pointer;
        width: 25px;
        margin: 30px 0 0 20px
    }
}

#section-security .tabs .expand-tabs .tab-icons {
    letter-spacing: -8px
}

#section-security.expanded .tabs .expand-tabs .tab-icons {
    transform: rotate(180deg)
}

#section-security .tab-content-parent {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row: content/margin-bottom;
    z-index: 1;
    padding-top: 15px
}

#section-security .tab-content .foreground {
    position: relative;
    z-index: 1;
}

#section-security .tab-pane {
    position: relative
}

#section-security.expanded {
    grid-template-columns:[margin-left] calc((100% - 1000px) / 2) [column-left] 400px [column-right] 600px [margin-right] auto [end];
    grid-auto-rows: max-content;
    width: 100%
}

@media (min-width: 1000px) {
    #section-security {
        grid-template-rows:[margin-top] 120px [content] 1fr [margin-bottom] 150px [end];
        grid-auto-rows: max-content;
        width: 100%
    }

    #section-security .tabs .expand-tabs {
        display: none
    }
}

#section-security .watermark {
    position: absolute;
    opacity: .06;
    width: 100%;
    top: 24px
}

#section-security .watermark i {
    font-size: 215px;
    text-align: center;
    display: block
}

@media (min-width: 500px) {
    #section-security .watermark i {
        font-size: 315px;
    }
}

#section-security .tab-content-parent p {
    line-height: 2.4
}

#section-security #v-pills-tabContent {
    height: 100%;
    background-color: #fff;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    max-width: 300px
}

@media (min-width: 420px) {
    #section-security #v-pills-tabContent {
        padding: 0 35px;
        max-width: 800px
    }
}

@media (min-width: 768px) {
    #section-security #v-pills-tabContent {
        padding: 0 50px 0 75px;
        max-width: 700px
    }
}

#section-security #v-pills-tabContent-parent {
    padding-bottom: 10px
}

#section-security .nav-pills .nav-link {
    margin-bottom: 5px;
    border-radius: 0;
    padding: 7px 5px
}

#section-security .nav-pills .nav-link, .nav-pills .show > .nav-link {
    border: none !important;
    transition: all 1s;
    max-height: 80px;
    overflow: hidden
}

#section-security .nav-pills .nav-link:hover {
    color: #fff;
    background-color: #fff;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, .05);
    transition: all 1s
}

#section-security .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #fff;
    border-left: 4px solid #39c !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 0 !important;
    transition: all 1s;
}

#section-testimonials {
    padding: 60px 0 100px 0;
    position: relative;
    top: -5px;
    z-index: 2;
    border-top: solid 1px #d3d3d3
}

@media (min-width: 800px) {
    #section-testimonials {
        padding: 120px 0
    }
}

#section-testimonials .story {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    margin: 0 auto 20px;
    border: 1px solid #dbdbdb;
}

#section-testimonials .story-info {
    line-height: 45px
}

#section-testimonials .swiper-button-prev::after {
    content: none
}

#section-testimonials .swiper-button-next::after {
    content: none
}

#section-testimonials .swiper-button-prev {
    left: calc(50% - 100px);
    top: unset;
    bottom: -52px;
    z-index: 50
}

#section-testimonials .swiper-button-next {
    right: calc(50% - 100px);
    top: unset;
    bottom: -52px;
    z-index: 50
}

#section-testimonials .swiper-pagination-fraction {
    bottom: -45px;
    font-weight: 700;
    color: #888;
    font-size: 16px
}

#section-testimonials .story-text {
    text-align: center;
    border-top: 1px solid #dbdbdb;
    font-family: Arial, sans-serif;
    height: 95px
}

#section-testimonials h2 {
    margin-bottom: 50px
}

#section-testimonials .story-text h3 {
    margin: 20px 0 1px 0 !important;
    font-size: 18px;
    text-transform: lowercase
}

#section-testimonials .story-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%
}

#section-testimonials .container {
    padding: 0 45px
}

@media (min-width: 768px) {
    #section-testimonials .swiper-button-prev {
        left: -40px;
        top: calc(50% - 30px)
    }

    #section-testimonials .swiper-button-next {
        right: -40px;
        top: calc(50% - 30px)
    }
}

@media (min-width: 1200px) {
    #section-testimonials .container {
        padding: 0 20px
    }
}

.modal-story {
    padding: 0 0 15px 0
}

.modal-story p {
    font-size: 14px;
    padding: 20px
}

.modal-story img {
    width: 100%
}

#section-footer {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 40px 0 20px 0;
    background-color: #1d3866;
    color: #fff;
    line-height: 24px
}

#section-footer a {
    font-size: 14px;
    color: #fff
}

#section-footer ul > li {
    color: #fff
}

#section-footer .powered-by-eurid {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 45px;
    width: 260px;
    margin: 30px
}

#section-footer .footer-certifications img {
    height: 100%;
    padding: 0 5px
}

#section-footer .internal-links ul {
    right: 0;
    bottom: 0;
    min-width: max-content;
    display: flex;
    align-self: end;
    width: 100%;
    justify-content: space-between
}

#section-footer .external-links ul {
    min-width: max-content;
    display: flex;
    flex-flow: row;
    align-content: flex-end;
    flex-wrap: wrap;
    margin: 20px 0 0 10px
}

#section-footer .internal-links {
    display: flex;
    height: 100%;
    padding: 0;
    flex-flow: row;
    flex-wrap: wrap;
    align-content: flex-end
}

#section-footer .external-links {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 40px 0 0 0;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: flex-end
}

#section-footer .footer-certifications {
    margin: 0;
    height: 45px;
    min-width: max-content;
    justify-content: flex-end;
    align-self: flex-end
}

@media (min-width: 420px) {
    #section-footer .internal-links {
        padding: 0 10px 0 50px
    }

    #section-footer .external-links {
        display: flex;
        height: 100%;
        padding: 0;
        flex-direction: row
    }

    #section-footer .footer-certifications { /*! margin: 0 35px; */
    }
}

#section-footer .external-links li {
    display: inline-block;
    padding: 0 0 0 5px
}

#section-footer .external-links li a i {
    font-size: 26px !important;
    color: #fff
}

#section-footer hr {
    border-top: solid 1px rgba(255, 255, 255, .5)
}

#section-footer .footer-logo img {
    max-width: min(30vw, 150px)
}

#section-footer .internal-links ul {
    list-style-type: none;
    display: grid;
    width: 100%;
    gap: 15px;
    grid-template-columns:1fr;
    padding: 10px 35px 20px 5px
}

@media (min-width: 650px) {
    #section-footer .internal-links ul {
        grid-template-columns:1fr 1fr;
        padding: 5px 5px 35px 5px
    }
}

@media (min-width: 960px) {
    #section-footer .internal-links ul {
        grid-template-columns:1fr 1fr;
        padding: 5px
    }
}

@media (min-width: 1320px) {
    #section-footer .internal-links ul {
        display: flex;
        justify-content: space-between
    }
}

#section-footer .footer-logo {
    height: 100px;
    padding: 8px 0 0 0;
    width: 150px;
    float: left
}

#section-footer .footer-logo .card-item {
    position: absolute;
    animation-name: logo-switcher;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards
}

@keyframes logo-switcher {
    0%, 100%, 33.333% {
        opacity: 0
    }
    2.0%, 31.333% {
        opacity: 1
    }
}

#section-footer .footer-logo .card-item:nth-of-type(1) {
    animation-delay: 0s
}

#section-footer .footer-logo .card-item:nth-of-type(2) {
    animation-delay: 10s;
    opacity: 0
}

#section-footer .footer-logo .card-item:nth-of-type(3) {
    animation-delay: 20s;
    opacity: 0
}

#section-footer .footer-copyright {
    padding: 35px 0 45px;
    color: #fff
}

:focus-visible {
    box-shadow: 0 0 0 3px #de0000 !important;
    border-radius: 2px
}
